import jQuery from "jquery";
import MicroModal from "micromodal";

import createModal from "./create_modal";

const BDC_HOST = new RegExp("^https?://(www.)?bdc.de");
const SURGEONTALK_HOST = new RegExp("^https?://(www.)?surgeontalk.de");
const MAIL_PREFIX = "mailto:";

const $ = jQuery;

export default function (text) {
  $(document).ready(() => {
    const id = "modal-external-link";
    const element = $(
      createModal(
        id,
        "Externer Link",
        text,
        "Weiter",
        "Zurück"
      )
    );
    const primaryButton = element.find(".modal__btn-primary");

    $(document.body).append(element);

    MicroModal.init();
    const show = (onConfirm) => {
      MicroModal.show(id, {
        onClose: () => {
          primaryButton.off(`click.${id}`)
        },
      });
      primaryButton.one(`click.${id}`, () => {
        MicroModal.close(id);
        onConfirm();
      });
    };

    const isExternalURL = (url) => new URL(url).origin !== location.origin;
    const isBdcURL = (url) => !!BDC_HOST.test(url);
    const isSurgeontalkURL = (url) => !!SURGEONTALK_HOST.test(url);
    const isMailAddress = (url) => url.startsWith(MAIL_PREFIX);
    const hasSkippingAttribute = (target) => target ? target.hasAttribute('data-skip-popup') : false

    $("a, button[data-sharer]").on("click", (event, _element) => {
      const target = event.currentTarget;
      const href = target?.href;
      if (!!href && href != "" && !hasSkippingAttribute(target) && !isBdcURL(href) && !isSurgeontalkURL(href) && !isMailAddress(href) && isExternalURL(href)) {
        show(() => {
          let options = null;
          if(target.hasAttribute('data-is-popup')) {
            const popWidth = 600;
            const popHeight = 480;
            options = `scrollbars=no, width=${popWidth}, height=${popHeight}, top=${window.innerHeight / 2 - popHeight / 2 + window.screenY}, left=${window.innerWidth / 2 - popWidth / 2 + window.screenX}`;
          }

          const newTab = window.open('', '_blank', options);
          newTab.opener = null;
          newTab.location = href;
          newTab.focus();
        });

        return false;
      } else {
        return true;
      }
    });
  });
}
